import React, { FunctionComponent, useContext, useState } from 'react';
import API from '../utils/api';
import Encryption from '../utils/encryption';
import { ErrorModalContext } from './ErrorModal';
const {
  Modal,
  Section,
  Columns,
  Button,
  Form: { Input, Field, Label, Control }
} = require('react-bulma-components');

type Props = {
  show: boolean;
  surveyId: string;
  onKey: (privateKey: CryptoKey, publicKey: string) => any;
};

const StatsLoginModal: FunctionComponent<Props> = ({ show, surveyId, onKey }) => {
  const { displayError } = useContext(ErrorModalContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onPasswordKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      onConfirm();
    }
  }

  const onConfirm = async () => {
    try {
      const hashedPassword = await Encryption.hash(password);
      const key = await API.getEncryptedPrivateKey(surveyId, email, hashedPassword);
      const privateKey = await Encryption.loadPrivateKey(key.encryptedPrivateKey, password);

      onKey(privateKey, key.publicKey);
    } catch {
      displayError('Password errata', true);
    }
  };

  return (
    <Modal show={show} showClose={false} onClose={() => {}}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          <Columns>
            <Columns.Column size="half">
              <Field>
                <Label>E-Mail</Label>
                <Control>
                  <Input
                    name="email"
                    type="text"
                    value={email}
                    onChange={(event: any) => setEmail(event.target.value)}
                  />
                </Control>
              </Field>
            </Columns.Column>
            <Columns.Column size="half">
              <Field>
                <Label>Password</Label>
                <Control>
                  <Input
                    name="password"
                    type="password"
                    value={password}
                    onChange={(event: any) => setPassword(event.target.value)}
                    onKeyDown={onPasswordKeyDown}
                  />
                </Control>
              </Field>
            </Columns.Column>
          </Columns>
          <Button
            color="link"
            style={{ margin: 'auto', marginTop: '2em', display: 'block' }}
            onClick={onConfirm}
          >
            Conferma
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default StatsLoginModal;
