import XLSX from 'xlsx';

const getCell = (page: XLSX.WorkSheet, x: number, y: number) => {
  var cell_ref = XLSX.utils.encode_cell({ c: x, r: y });
  return page[cell_ref.toString()]?.w;
};

const stringToNumber = (s: string) => {
  return parseFloat(s.trim().replace(',', '.'));
};

const findCategories = (categoriesPage: XLSX.WorkSheet) => {
  const cats = [];
  let i = 1;

  while (true) {
    const catName = getCell(categoriesPage, 0, i);
    const catWeight = getCell(categoriesPage, 1, i);
    const catDescription = getCell(categoriesPage, 2, i);

    if (catName === undefined && catWeight === undefined) break;
    else if (catName !== undefined && catWeight !== undefined) {
      const catWeightNum = stringToNumber(catWeight);
      if (isNaN(catWeightNum)) {
        throw new Error(`RIGA ${i + 1} | Categoria invalida, il peso non è un numero`);
      }
      cats.push({
        name: catName,
        weight: catWeightNum,
        description: catDescription
      });
      i++;
    } else {
      throw new Error(`RIGA ${i + 1} | Categoria invalida`);
    }
  }

  return cats;
};

const findQuestions = (questionsPage: XLSX.WorkSheet) => {
  const questions = [];

  let i = 1;
  let id = undefined;
  let questionText = undefined;
  let type = undefined;
  let category = undefined;
  while (true) {
    id = getCell(questionsPage, 0, i);
    questionText = getCell(questionsPage, 2, i);
    type = getCell(questionsPage, 3, i);
    category = getCell(questionsPage, 5, i);

    if (
      id === undefined &&
      questionText === undefined &&
      type === undefined &&
      category === undefined
    )
      break;

    const question: any = {
      id,
      category,
      text: questionText,
      answers: [] as any[]
    };

    if (type === 'nessuna') question.type = 'none';
    else if (type === 'tendina') question.type = 'dropdown';
    else if (type === 'multipla') question.type = 'single';
    else if (type === 'immagine') question.type = 'image';
    else if (type.startsWith('multipla+')) {
      question.type = 'multiple';
      question.limit = parseInt(type.split('+')[1]);
    } else throw new Error(`RIGA ${i + 1} Tipo della domanda "${type}" invalido`);

    if (question.type !== 'none' && question.type !== 'image') {
      do {
        const answerText = getCell(questionsPage, 4, i);
        const value = getCell(questionsPage, 6, i);
        const unlocks = getCell(questionsPage, 7, i);

        if (answerText === undefined) break;

        const answer: any = {
          text: answerText
        };

        const valueNum = stringToNumber(value || '0');
        if (isNaN(valueNum)) {
          throw new Error(`RIGA ${i + 1} | Risposta invalida, il peso non è un numero`);
        }
        answer.value = valueNum;

        if (unlocks) answer.unlocks = unlocks.split(' ');

        question.answers.push(answer);
      } while (getCell(questionsPage, 2, ++i) === undefined);
    } else {
      i++;
    }

    questions.push(question);
  }

  return questions;
};

const normalizeUnlocks = (questions: any[]) => {
  const findIndex = (id: string) => {
    for (const i in questions) if (questions[i].id === id) return parseInt(i);

    throw new Error(`SBLOCCO | Domanda ${id} non trovata`);
  };

  for (const q of questions)
    for (const a of q.answers)
      if (a.unlocks) {
        const newUnlocks = [];
        for (const u of a.unlocks) {
          newUnlocks.push(findIndex(u));
        }
        a.unlocks = newUnlocks;
      }
};

export default (data: Uint8Array) => {
  const xlsx = XLSX.read(data, { type: 'array' });
  const questionsPage = xlsx.Sheets[Object.keys(xlsx.Sheets)[0]];
  const categoriesPage = xlsx.Sheets[Object.keys(xlsx.Sheets)[1]];

  const categories = findCategories(categoriesPage);
  const questions = findQuestions(questionsPage);

  //TODO: checkCategories(categories, questions)
  normalizeUnlocks(questions);

  return {
    report: {
      questions: questions.length,
      categories: categories.length
    },
    survey: {
      categories,
      questions
    }
  };
};
