import React, { FunctionComponent, Fragment } from 'react';
const { Field, Label, Control, Radio } = require('react-bulma-components').Form;

type Props = {
  question: any;
  userAnswer: number[];
  onChange: (answer: number[]) => any;
};

const SingleChoice: FunctionComponent<Props> = ({ question, userAnswer, onChange }) => {
  const onChangeEvent = (event: any) => {
    const answer = parseInt(event.target.value);
    if (userAnswer[0] !== answer) {
      onChange([answer]);
    }
  };

  return (
    <Field>
      <Label className="question-title">{question.text}</Label>
      <Control>
        {question.answers.map((answer: any, i: number) => (
          <Fragment key={i}>
            <Radio
              value={i.toString()}
              checked={userAnswer[0] === i}
              name={`answer-${question.id}`}
              onChange={onChangeEvent}
              style={{ fontWeight: userAnswer[0] === i ? '700' : '400' }}
            >
              {' ' + answer.text}
            </Radio>
            <br />
          </Fragment>
        ))}
      </Control>
    </Field>
  );
};

export default SingleChoice;
