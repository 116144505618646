import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api';
import Encryption from '../../utils/encryption';
import { ErrorModalContext } from '../../components/ErrorModal';

const LoginPage: FunctionComponent = () => {
  const { displayError } = useContext(ErrorModalContext);
  const history = useHistory();
  const [password, setPassword] = useState('');

  useEffect(() => {
    (async () => {
      try {
        await API.checkJWT();
        history.push('/admin/surveys');
      } catch {}
    })();
    // eslint-disable-next-line
  }, []);

  const onPasswordKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      onLogin();
    }
  };

  const onLogin = async () => {
    try {
      await API.adminLogin(await Encryption.hash(password));
      history.push('/admin/surveys');
    } catch (error) {
      console.log(error);
      displayError('Login fallito', true);
    }
  };

  return (
    <>
      <section className="hero has-background-grey-lighter is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12-mobile is-8-desktop">
                <div className="box">
                  <div className="field has-text-centered">
                    <h1 className="title">CyberReadiness.IT</h1>
                    <h3 className="subtitle">Piattaforma di amministrazione</h3>
                    <div className="control">
                      <input
                        type="password"
                        placeholder="password"
                        className="input"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyDown={onPasswordKeyDown}
                      />
                    </div>
                  </div>
                  <div className="field has-text-centered">
                    <button className="button is-success" onClick={onLogin}>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
