import React, { FunctionComponent, useContext, useState } from 'react';
import Encryption from '../../utils/encryption';
import is from '@sindresorhus/is';
import { ErrorModalContext } from '../ErrorModal';
const {
  Modal,
  Section,
  Columns,
  Button,
  Heading,
  Form: { Input, Field, Label, Control }
} = require('react-bulma-components');

type Props = {
  show: boolean;
  onClose: () => any;
  onSuccess: (key: any) => any;
  surveyId: string;
};

enum Steps {
  ALERT,
  EMAIL_AND_PASSWORD,
  KEY_GENETATION_AND_SUBMISSION
}

const AnalystModal: FunctionComponent<Props> = ({ show, onClose, onSuccess, surveyId }) => {
  const { displayError } = useContext(ErrorModalContext);
  const [step, setStep] = useState(Steps.ALERT);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassowrd, setConfirmPassword] = useState('');

  const generateAndSend = async () => {
    if (is.emptyStringOrWhitespace(email) || is.emptyStringOrWhitespace(password)) return;
    setStep(Steps.KEY_GENETATION_AND_SUBMISSION);
    try {
      const keypair = await Encryption.generateKeypair(password);
      const hashedPassword = await Encryption.hash(password);
      onSuccess({
        email,
        hashedPassword,
        publicKey: keypair.publicKey,
        encryptedPrivateKey: keypair.encryptedPrivateKey,
        timestamp: new Date()
      });
    } catch (error) {
      displayError(error, false);
    }
  };

  return (
    <Modal show={show} showClose={true} closeOnBlur={true} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          {step === Steps.ALERT && (
            <>
              <Heading size={2} renderAs="p">
                !Attenzione!
              </Heading>

              <p>
                Stai per creare un nuovo analista.
                <br />
                <br />
                Per via dell'architettura privacy-oriented{' '}
                <b>non è possibile recuperare/cambiare la password</b> in caso di smarrimento.{' '}
                <br />
                <br />Gli analisti aggiunti durante la somministrazione del questionario{' '}
                <b>
                  potranno leggere solamente le risposte inviate successivamente alla loro aggiunta
                  al questionario.
                </b>
              </p>

              <Button
                color="link"
                style={{ margin: 'auto', marginTop: '2em', display: 'block' }}
                onClick={() => setStep(Steps.EMAIL_AND_PASSWORD)}
              >
                Continua
              </Button>
            </>
          )}
          {step === Steps.EMAIL_AND_PASSWORD && (
            <>
              <p>
                L'indirizzo E-Mail <b>non sara' verificato</b>, controlla di averlo inserito correttamente
                prima di continuare.
              </p>

              <br />

              <Field>
                <Label>E-Mail</Label>
                <Control>
                  <Input
                    name="email"
                    type="text"
                    value={email}
                    onChange={(event: any) => setEmail(event.target.value)}
                  />
                </Control>
              </Field>

              <Columns>
                <Columns.Column size="half">
                  <Field>
                    <Label>Password</Label>
                    <Control>
                      <Input
                        name="password"
                        type="password"
                        value={password}
                        onChange={(event: any) => setPassword(event.target.value)}
                        color={(password !== confirmPassowrd && 'danger') || 'success'}
                      />
                    </Control>
                  </Field>
                </Columns.Column>
                <Columns.Column size="half">
                  <Field>
                    <Label>Conferma Password</Label>
                    <Control>
                      <Input
                        name="confirm-password"
                        type="password"
                        value={confirmPassowrd}
                        onChange={(event: any) => setConfirmPassword(event.target.value)}
                        color={(password !== confirmPassowrd && 'danger') || 'success'}
                      />
                    </Control>
                  </Field>
                </Columns.Column>
              </Columns>

              <Button
                color="link"
                style={{ margin: 'auto', marginTop: '2em', display: 'block' }}
                onClick={generateAndSend}
              >
                Continua
              </Button>
            </>
          )}
          {step === Steps.KEY_GENETATION_AND_SUBMISSION && (
            <>Generazione chiave in corso, attendi...</>
          )}
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default AnalystModal;
