import React, { FunctionComponent, useEffect, useState } from 'react';
import API from '../../utils/api';
const { Modal, Section } = require('react-bulma-components');

const Logout: FunctionComponent = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  useEffect(() => {
    (async () => {
      await API.logout();
      setLoggedOut(true);
    })();
  }, []);

  return (
    <Modal show={true} showClose={false} onClose={() => {}}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          {loggedOut ? <>Logout effettuato con successo</> : <>Logout in corso...</>}
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default Logout;
