import React, { FunctionComponent } from 'react';

const Home: FunctionComponent = () => {
  return (
    <>
      <section className="hero is-fullheight has-background-grey-lighter">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12-mobile is-8-desktop">
                <div className="box" style={{ padding: '2rem', paddingTop: '3rem' }}>
                  <div className="columns is-centered is-vcentered">
                    <div className="column is-12-mobile is-36-fullhd has-text-centered">
                      <a
                        href="https://cybersecnatlab.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="cybersecnatlab.png"
                          alt="Logo CyberSecNatLAb"
                          style={{ width: '75%' }}
                        />
                      </a>
                    </div>
                    <div className="column is-12-mobile is-6-fullhd has-text-centered">
                      <a href="#/" target="_blank" rel="noopener noreferrer">
                        <img
                          src="logo.svg"
                          alt="Logo CyberReadiness.IT"
                          style={{ width: '100%' }}
                        />
                      </a>
                    </div>
                  </div>

                  <div style={{ marginTop: '1.5rem' }}>
                    La gestione del rischio cyber è diventato un elemento fondamentale per
                    assicurare un’adeguata protezione dei dati, delle reti e dei sistemi informatici
                    dell’organizzazione. L’utente umano è sempre più spesso il target privilegiato
                    degli attacchi cyber. Diventa dunque rilevante riuscire a ridurre le
                    vulnerabilità e i rischi legati al fattore umano (human factor) e rafforzare la
                    sicurezza informatica dell’intera organizzazione.
                  </div>
                  <div style={{ marginTop: '1.5rem' }}>
                    Il progetto CyberReadiness.IT del{' '}
                    <a href="https://cybersecnatlab.it/" target="_blank" rel="noopener noreferrer">
                      Laboratorio Nazionale di Cybersecurity
                    </a>{' '}
                    si pone l’obiettivo di fornire gli strumenti utili a misurare l’impatto del
                    fattore umano nella valutazione dei rischi informatici in una determinata
                    organizzazione. Il questionario rappresenta lo strumento utile per raggiungere
                    tale obiettivo e permette, tra le altre cose, di valutare gli aspetti
                    potenzialmente critici come il livello di conoscenza di un insieme base di buone
                    pratiche di cybersecurity, degli standard e delle misure relative alla sicurezza
                    informatica definiti all’interno dell’organizzazione, il livello di cyber
                    hygiene individuale e, nel complesso, i punti di forza e debolezza del singolo
                    utente rispetto ai rischi informatici. Per accedere al questionario viene
                    fornito un identificativo anonimo a ciascun utente, che può essere usato per
                    successivi accessi al questionario da parte dello stesso.{' '}
                  </div>
                  <div style={{ marginTop: '1.5rem' }}>
                    I risultati del questionario offrono una valutazione sul livello di sicurezza e
                    di esposizione dell’individuo e dell’organizzazione, rapportandoli alle
                    criticità derivanti dal fattore umano. In questo modo è possibile aumentare la
                    consapevolezza e le capacità del singolo utente e, in generale,
                    dell’organizzazione rispetto ai rischi cyber. In particolare, i risultati
                    permettono di valutare l’assessment della cybersecurity di ciascun utente e, a
                    livello di organizzazione, le capacità di risposta, anche in relazione ai
                    cambiamenti della minaccia e ai rischi cyber correlati con il fattore umano. In
                    entrambi i casi, grazie a questi, è possibile proporre un adeguato insieme di
                    azioni, volto a mitigare gli elementi di criticità rilevati, attraverso
                    strumenti di remediation specifici e personalizzati in base sia agli esiti del
                    questionario sia alle esigenze dell’organizzazione committente. Infatti,
                    l’output finale è uno score ponderato sulle domande e le risposte ricevute,
                    sulla base dei fattori di riferimento.
                  </div>
                  <div style={{ marginTop: '1.5rem' }}>
                    Il questionario è sempre somministrato in modo anonimo e le risposte vengono
                    trattate nel pieno rispetto degli standard tecnici, giuridici e di
                    accountability.{' '}
                  </div>
                  <div style={{ marginTop: '1.5rem' }}>
                    Il{' '}
                    <a href="https://cybersecnatlab.it/" target="_blank" rel="noopener noreferrer">
                      Laboratorio Nazionale di Cybersecurity
                    </a>{' '}
                    si riserva la possibilità di condividere dati aggregati scaturiti dall’analisi
                    delle risposte con stakeholder pubblici e privati.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
