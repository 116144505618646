import React, { FunctionComponent } from 'react';
const { Modal, Section, Button } = require('react-bulma-components');

type Props = {
  show: boolean;
  content: any;
  button: string;
  onDelete: () => any;
  onCancel: () => any;
};

const ConfirmModal: FunctionComponent<Props> = ({ show, content, button, onDelete, onCancel }) => {
  return (
    <Modal show={show} showClose={true} closeOnBlur={true} onClose={onCancel}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          {content}
          <Button.Group>
            <Button color="danger" onClick={onDelete}>
              {button}
            </Button>
            <Button color="info" onClick={onCancel}>
              Annulla
            </Button>
          </Button.Group>
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmModal;
