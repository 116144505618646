import React, { FunctionComponent } from 'react';
import { Chart } from 'react-charts';
const {
  Form: { Label }
} = require('react-bulma-components');

type Props = {
  answer: any;
};

const AnswerChart: FunctionComponent<Props> = (props) => {
  const charts = [];
  let answers = [];
  for (const answer of props.answer) {
    answers.push(answer);
    const title = answer.title;
    const data = [];
    for (const i in answer.values.texts) {
      data.push({
        label: answer.values.texts[i],
        data: [
          {
            primary: title,
            secondary: answer.values.values[i].toString()
          }
        ]
      });
    }

    charts.push(
      <div key={answer.id} style={{ marginTop: '4rem', height: '500px', maxHeight: '500px' }}>
        <Label className="question-title">{title}</Label>
        <Chart
          data={data}
          series={{ type: 'bar' }}
          axes={[
            { primary: true, type: 'ordinal', position: 'bottom' },
            { position: 'left', type: 'linear', stacked: false, show: true }
          ]}
          tooltip={true}
        />
      </div>
    );
  }

  console.log(answers);

  return <>{charts}</>;
};

export default AnswerChart;
