import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import API from '../utils/api';
import { ErrorModalContext } from '../components/ErrorModal';
import AnswerChart from '../components/stats/AnswerChart';
import Encryption from '../utils/encryption';
import StatsDecryptionModal from '../components/StatsDecriptionModal';
import StatsLoginModal from '../components/StatsLoginModal';
import { decodeId } from '../utils/encode-id';
const { Container, Heading } = require('react-bulma-components');

type Props = RouteComponentProps<{
  surveyId: string;
}>;

const Stats: FunctionComponent<Props> = (props) => {
  const errorModalContext = useContext(ErrorModalContext);
  const [answers, setAnswers] = useState(undefined as any);
  const [survey, setSurvey] = useState(undefined as any);
  const [decryptionPercentage, setDecryptionPercentage] = useState(0);

  useEffect(() => {
    if (survey && decodeId(props.match.params.surveyId) !== survey._id) {
      setAnswers(undefined);
      setSurvey(undefined);
    }
    // eslint-disable-next-line
  }, [props.match.params.surveyId]);

  const onKey = (privateKey: CryptoKey, publicKey: string) => {
    (async () => {
      try {
        const survey = await API.getSurvey(decodeId(props.match.params.surveyId));
        setSurvey(survey);
        const encryptedAnswers = await API.getAnswers(
          decodeId(props.match.params.surveyId),
          publicKey
        );

        setDecryptionPercentage(1);
        const answers = [];
        let count = 0;
        let invalidAnswers = 0;
        for (const encryptedAnswer of encryptedAnswers) {
          try {
            const answerString = await Encryption.decrypt(privateKey, encryptedAnswer.answer);
            answers.push({ ...encryptedAnswer, answer: JSON.parse(answerString) });
          } catch (error) {
            console.error(error);
            invalidAnswers++;
          }

          setDecryptionPercentage(++count / encryptedAnswers.length);
        }

        console.log(`Invalid answers: ${invalidAnswers}`);

        console.log(`${answers.length} answers:`);
        console.log(answers);

        onAnswers(answers, survey);
        setDecryptionPercentage(0);
      } catch (error) {
        errorModalContext.displayError(error.toString());
      }
    })();
    // eslint-disable-next-line
  };

  const onAnswers = (answers: any, survey: any) => {
    let data = [];

    let lines = [];

    for (let i = 0; i < survey.questions.length; i++) {
      if (survey.questions[i].type === 'none') continue;

      const answerData = {
        texts: [''],
        values: [0]
      };

      lines.push('"' + survey.questions[i].text.replace('"', "'") + '"');

      for (let j = 0; j < survey.questions[i].answers.length; j++) {
        let text = survey.questions[i].answers[j];

        if (text.text) {
          text = text.text;
        }

        answerData.texts[j] = text;
        answerData.values[j] = 0;
      }

      for (const answer of answers) {
        try {
          if (answer.answer[i] !== undefined) {
            for (const id of answer.answer[i]) answerData.values[id]++;
          }
        } catch {
          //Invalid answer
        }
      }

      for (let j = 0; j < survey.questions[i].answers.length; j++) {
        lines.push('"' + answerData.texts[j].replace('"', "'") + '",' + answerData.values[j]);
      }

      data.push({
        id: i,
        title: survey.questions[i].text,
        values: answerData
      });

      lines.push('');
      setAnswers(data);
    }

    console.log(lines.join('\n'));
  };

  return (
    <section className="hero has-background-grey-lighter is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-12-mobile is-10-desktop is-8-widescreen">
              <div className="box" style={{ padding: '2rem', paddingTop: '3rem' }}>
                <div className="columns is-centered is-vcentered">
                  <div className="column is-12-mobile is-36-fullhd has-text-centered">
                    <a href="https://cybersecnatlab.it/" target="_blank" rel="noopener noreferrer">
                      <img
                        src="cybersecnatlab.png"
                        alt="Logo CyberSecNatLAb"
                        style={{ width: '75%' }}
                      />
                    </a>
                  </div>
                  <div className="column is-12-mobile is-6-fullhd has-text-centered">
                    <a href="#/" target="_blank" rel="noopener noreferrer">
                      <img src="logo.svg" alt="Logo CyberReadiness.IT" style={{ width: '100%' }} />
                    </a>
                  </div>
                </div>

                <div className="field">
                  {!answers && (
                    <StatsLoginModal
                      show={true}
                      surveyId={decodeId(props.match.params.surveyId)}
                      onKey={onKey}
                    />
                  )}
                  {!answers && <StatsDecryptionModal percentage={decryptionPercentage} />}
                  {(answers && (
                    <Container style={{ padding: '1em' }}>
                      <Heading className="has-text-centered">{survey.title}</Heading>
                      <AnswerChart answer={answers} />
                    </Container>
                  )) || <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
