import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const {
  Modal,
  Section,
  Button,
  Form: { Input, Field },
  Heading
} = require('react-bulma-components');

type Props = {
  surveyUrl?: string;
  statsUrl?: string;
  onClose: () => any;
};

const URLModal: FunctionComponent<Props> = ({ surveyUrl, statsUrl, onClose }) => {
  const [surveyCopied, setSurveyCopied] = useState(false);
  const [statsCopied, setStatsCopied] = useState(false);

  return (
    <Modal show={!!surveyUrl || !!statsUrl} showClose={true} closeOnBlur={true} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          <Heading size={4} style={{ marginBottom: '0.5rem' }}>
            Questionario
          </Heading>
          <Field className="has-addons">
            <Input readOnly={true} value={surveyUrl} />

            <CopyToClipboard text={surveyUrl || ''} onCopy={() => setSurveyCopied(true)}>
              <Button color={surveyCopied ? 'success' : undefined}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </Button>
            </CopyToClipboard>
          </Field>
          <br />
          <br />
          <Heading size={4} style={{ marginBottom: '0.5rem' }}>
            Statistiche
          </Heading>
          <Field className="has-addons">
            <Input readOnly={true} value={statsUrl} />

            <CopyToClipboard text={statsUrl || ''} onCopy={() => setStatsCopied(true)}>
              <Button color={statsCopied ? 'success' : undefined}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </Button>
            </CopyToClipboard>
          </Field>
          <Button
            color="link"
            style={{ margin: 'auto', marginTop: '2em', display: 'block' }}
            onClick={onClose}
          >
            Chiudi
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default URLModal;
