import React, { FunctionComponent, Fragment } from 'react';
const {
  Form: { Field, Label, Control, Checkbox }
} = require('react-bulma-components');

type Props = {
  question: any;
  userAnswer: number[];
  onChange: (answer: number[]) => any;
};

const MultipleChoice: FunctionComponent<Props> = ({ question, userAnswer, onChange }) => {
  const onChangeEvent = (i: number) => (event: any) => {
    const present = userAnswer.indexOf(i);
    if (event.target.checked && present < 0) {
      if (userAnswer.length === question.limit) return;
      onChange([...userAnswer, i]);
    } else if (!event.target.checked && present >= 0) {
      userAnswer.splice(present, 1);
      onChange([...userAnswer]);
    }
  };

  return (
    <Field>
      <Label className="question-title">{question.text}</Label>
      <Control>
        {question.answers.map((answer: any, i: number) => (
          <Fragment key={i}>
            <Checkbox
              checked={userAnswer.includes(i)}
              name={`answer-${question.id}`}
              onChange={onChangeEvent(i)}
              style={{ fontWeight: userAnswer.includes(i) ? '700' : '400' }}
            >
              {' ' + answer.text}
            </Checkbox>
            <br />
          </Fragment>
        ))}
      </Control>
    </Field>
  );
};

export default MultipleChoice;
