import React, { FunctionComponent, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Surveys from './Surveys';
import Survey from './Survey';
import Login from './Login';
import API from '../../utils/api';
import Logout from './Logout';

const Router: FunctionComponent = () => {
  const { path } = useRouteMatch();
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await API.checkJWT();
        setLogged(true);
      } catch {}
    })();
  });

  return (
    <>
      {logged ? (
        <Switch>
          <Route path={`${path}/surveys`} component={Surveys} />
          <Route path={`${path}/survey/:id`} component={Survey} />
          <Route path={`${path}/logout`} component={Logout} />
        </Switch>
      ) : <Login />}
    </>
  );
};

export default Router;
