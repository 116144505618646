import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import API from '../../utils/api';
import { ErrorModalContext } from '../../components/ErrorModal';
import moment from 'moment';
import './Surveys.css';
import DatePicker from 'react-datepicker';
import AnalystModal from '../../components/admin/AnalystModal';
import ConfirmModal from '../../components/admin/ConfirmModal';
import parser from '../../utils/parser';
import InputFile from '../../components/admin/InputFile';
import { decodeId } from '../../utils/encode-id';
const {
  Form: { Field, Label, Control, Input },
  Button,
  Heading,
  Container,
  Columns,
  Table
} = require('react-bulma-components');

type Props = RouteComponentProps<{
  id: string;
}>;

const DEFAULT = {
  title: '',
  keys: []
};

const Survey: FunctionComponent<Props> = (props) => {
  const errorModalContext = useContext(ErrorModalContext);
  const history = useHistory();
  const [survey, setSurvey] = useState(undefined as any);
  const [modal, setModal] = useState(<></>);
  const [uploadReport, setUploadReport] = useState(<></>);
  const [edited, setEdited] = useState(false);

  const newSurvey = props.match.params.id === 'new';

  useEffect(() => {
    errorModalContext.displayError('');
    if (!newSurvey) {
      (async () => {
        try {
          const survey = await API.getSurvey(decodeId(props.match.params.id));
          setSurvey(survey);
          setUploadReport(
            <>
              È presente un questionario con {survey.questions.length} domande e {survey.categories.length} categorie
              {survey.uploadTimestamp
                ? ', caricato il ' + moment(survey.uploadTimestamp).format('DD-MM-yyyy HH:mm')
                : ''}
            </>
          );
        } catch (error) {
          errorModalContext.displayError(error.toString(), false);
        }
      })();
    } else {
      setSurvey(DEFAULT);
    }
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const onDateChange = (start: Date, end: Date) => {
    setSurvey({
      ...survey,
      start: start && moment(start).toDate(),
      end: end && moment(end).toDate()
    });
    setEdited(true);
  };

  const addAnalyst = async (key: any) => {
    try {
      const keys = survey.keys;
      keys.push(key);
      setSurvey({
        ...survey,
        keys
      });
      setEdited(true);
    } catch (error) {
      errorModalContext.displayError(error.toString(), true);
    }

    setModal(<></>);
  };

  const removeAnalyst = async (i: number) => {
    try {
      const keys = survey.keys;
      keys.splice(i, 1);
      setSurvey({
        ...survey,
        keys
      });
      setEdited(true);
    } catch (error) {
      errorModalContext.displayError(error.toString(), true);
    }
  };

  const onFileUpload = async (file?: File) => {
    /*if (survey.answers > 0) {
      return errorModalContext.displayError(
        'Sono presenti risposte, non puoi modificare il questionario',
        true
      );
    }*/

    if (file) {
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result!);
        try {
          const { report, survey: surveyJSON } = parser(data);
          setUploadReport(
            <>
              {fileName}
              <br />
              Questionario importato con successo, sono state trovate {report.questions} domande in {report.categories}{' '}
              categorie
            </>
          );
          setSurvey({
            ...survey,
            uploadTimestamp: new Date(),
            ...surveyJSON
          });
          setEdited(true);
        } catch (error) {
          setUploadReport(<>Errore: {error.message}</>);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const saveSurvey = async () => {
    try {
      if (newSurvey) {
        await API.createSurvey(survey);
      } else {
        await API.updateSurvey(survey);
      }
      history.push('/admin/surveys');
    } catch (error) {
      errorModalContext.displayError(error.toString(), true);
    }
  };

  const onCancel = () => {
    if (edited) {
      setModal(
        <ConfirmModal
          show={true}
          content={<>Hai effettuto delle modifiche, vuoi scartarle?</>}
          button={'Continua'}
          onDelete={async () => {
            setModal(<></>);
            history.goBack();
          }}
          onCancel={() => {
            setModal(<></>);
          }}
        />
      );
    } else {
      history.goBack();
    }
  };

  return (
    (survey && (
      <>
        {modal}

        <section className="hero has-background-grey-lighter is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12-mobile">
                  <div className="box">
                    <div className="field">
                      <Container style={{ padding: '1em', backgroundColor: '#fff' }}>
                        <Heading size={3} className="has-text-centered">
                          Configura questionario {survey.title}
                        </Heading>

                        <Field>
                          <Label>Titolo</Label>
                          <Control>
                            <Input
                              value={survey.title}
                              onChange={(event: any) =>
                                setSurvey({
                                  ...survey,
                                  title: event.target.value
                                })
                              }
                            />
                          </Control>
                        </Field>
                        <Columns>
                          <Columns.Column size="half">
                            <Field>
                              <Label>Inizio</Label>
                              <Control>
                                <DatePicker
                                  selected={survey.start && moment(survey.start).toDate()}
                                  dateFormat="dd/MM/yyyy HH:mm"
                                  onChange={(date: Date) => {
                                    if (date === null || !isNaN(date.getTime())) onDateChange(date, survey.end);
                                  }}
                                  customInput={
                                    // "Native" react-bulma-components Input does not support refs
                                    <input className="input" type="text" />
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  isClearable
                                  placeholderText="Immediatamente disponibile"
                                  className={'timeinput'}
                                  startDate={moment(survey.start).toDate()}
                                  endDate={moment(survey.end).toDate()}
                                />
                              </Control>
                            </Field>
                          </Columns.Column>
                          <Columns.Column size="half">
                            <Field>
                              <Label>Fine</Label>
                              <Control>
                                <DatePicker
                                  selected={survey.end && moment(survey.end).toDate()}
                                  dateFormat="dd/MM/yyyy HH:mm"
                                  onChange={(date: Date) => {
                                    if (date === null || !isNaN(date.getTime())) onDateChange(survey.start, date);
                                  }}
                                  customInput={
                                    // "Native" react-bulma-components Input does not support refs
                                    <input className="input" type="text" />
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  isClearable
                                  placeholderText="Nessun termine"
                                  className={'timeinput'}
                                  startDate={moment(survey.start).toDate()}
                                  endDate={moment(survey.end).toDate()}
                                />
                              </Control>
                            </Field>
                          </Columns.Column>
                        </Columns>

                        <Field>
                          <Label>File del questionario</Label>
                          <Columns>
                            <Columns.Column size={3}>
                              <Control>
                                <InputFile onChange={onFileUpload} />
                              </Control>
                            </Columns.Column>
                            <Columns.Column size={9}>{uploadReport}</Columns.Column>
                          </Columns>
                        </Field>

                        <Heading size={3} renderAs="p" className="has-text-centered">
                          Analisti
                        </Heading>

                        <div className="has-text-centered">
                          <Button
                            color="success"
                            onClick={() =>
                              setModal(
                                <AnalystModal
                                  show={true}
                                  onSuccess={addAnalyst}
                                  onClose={() => setModal(<></>)}
                                  surveyId={survey._id}
                                />
                              )
                            }
                          >
                            Aggiungi analista
                          </Button>
                        </div>

                        <Table style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th>E-Mail</th>
                              <th>Data aggiunta</th>
                              <th>Azioni</th>
                            </tr>
                          </thead>
                          <tbody>
                            {survey.keys.map((key: any, i: number) => (
                              <tr key={key.publicKey}>
                                <th style={{ verticalAlign: 'middle' }}>{key.email}</th>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {key.timestamp && moment(key.timestamp).format('DD/MM/yyyy HH:mm')}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      setModal(
                                        <ConfirmModal
                                          show={true}
                                          content={
                                            <>
                                              Sei sicuro di voler eliminare <b>{key.email}</b>
                                            </>
                                          }
                                          button={'Elimina'}
                                          onDelete={() => {
                                            removeAnalyst(i);
                                            setModal(<></>);
                                          }}
                                          onCancel={() => setModal(<></>)}
                                        />
                                      );
                                    }}
                                  >
                                    Elimina
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Field kind="group" className="has-text-centered">
                          <Control>
                            <Button color="info" onClick={saveSurvey}>
                              Salva
                            </Button>
                          </Control>
                          <Control>
                            <Button color="danger" onClick={onCancel}>
                              Annulla
                            </Button>
                          </Control>
                        </Field>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )) || <></>
  );
};

export default Survey;
