import React, { FunctionComponent } from 'react';
const { Element, Icon } = require('react-bulma-components');

type Props = {
  onChange: (file: any) => any;
};

const InputFile: FunctionComponent<Props> = ({ onChange }) => {
  return (
    <Element className="file is-boxed">
      <label className="file-label">
        <input
          name="file-input"
          type="file"
          className="file-input"
          onChange={(event) => onChange(event.target.files && event.target.files[0])}
          value=""
        />
        <span className="file-cta">
          <span className="file-icon">
            <Icon icon="upload" />
          </span>
          <span className="file-label">Carica un file</span>
        </span>
      </label>
    </Element>
  );
};

export default InputFile;
