import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Survey from './pages/Survey';
import { HashRouter, Switch, Route } from 'react-router-dom';
import ErrorModal from './components/ErrorModal';
import Stats from './pages/Stats';
import Scores from './pages/Scores';
import AdminRouter from './pages/admin/Router';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

ReactDOM.render(
  <React.StrictMode>
    <ErrorModal>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/survey/:id" component={Survey} />
          <Route path="/scores/:userId/:surveyId" component={Scores} />
          <Route path="/stats/:surveyId" component={Stats} />
          <Route path="/admin" component={AdminRouter} />
          <Route component={NotFound} />
        </Switch>
      </HashRouter>
    </ErrorModal>
  </React.StrictMode>,
  document.getElementById('root')
);
