import React, { FunctionComponent, useEffect } from 'react';
import './DropDown.css';

type Props = {
  question: any;
  userAnswer: number[];
  onChange: (answer: number[]) => any;
};

const ImageComponent: FunctionComponent<Props> = ({ question, userAnswer, onChange }) => {
  useEffect(() => {
    if (userAnswer.length === 0) {
      onChange([-1]);
    }
    // eslint-disable-next-line
  }, []);

  return <img src={question.text} alt="question caption" className="is-fullwidth" />;
};

export default ImageComponent;
