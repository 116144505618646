import React, { FunctionComponent, useEffect } from 'react';
import './DropDown.css';
const { Content } = require('react-bulma-components');

type Props = {
  question: any;
  userAnswer: number[];
  onChange: (answer: number[]) => any;
};

const Text: FunctionComponent<Props> = ({ question, userAnswer, onChange }) => {
  useEffect(() => {
    if (userAnswer.length === 0) {
      onChange([-1]);
    }
    // eslint-disable-next-line
  }, []);

  return <Content style={{ whiteSpace: 'pre-line' }}>{question.text}</Content>;
};

export default Text;
