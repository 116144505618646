import React, { FunctionComponent, useState } from 'react';
import './ErrorModal.css';
const { Modal, Section } = require('react-bulma-components');

export const ErrorModalContext = React.createContext({
  displayError: (message: string, recoverable: boolean = false) => {}
});

const ErrorModal: FunctionComponent = (props) => {
  const [message, setMessage] = useState(undefined as string | undefined);
  const [closable, setClosable] = useState(false);
  const displayError = (message: string, recoverable: boolean = false) => {
    if (message) console.error(message);
    setMessage(message);
    setClosable(recoverable);
  };

  const onClose = () => {
    if (closable) {
      setMessage(undefined);
    }
  };

  return (
    <ErrorModalContext.Provider value={{ displayError }}>
      <Modal show={!!message} showClose={closable} closeOnBlur={true} onClose={onClose} className="error-modal has-text-centered">
        <Modal.Content>
          <Section style={{ backgroundColor: 'white' }}>{message}</Section>
        </Modal.Content>
      </Modal>
      {props.children}
    </ErrorModalContext.Provider>
  );
};

export default ErrorModal;
