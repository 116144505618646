import React, { FunctionComponent } from 'react';
const { Modal, Section } = require('react-bulma-components');

type Props = {
  percentage: number;
};

const StatsDecriptionModal: FunctionComponent<Props> = (props) => {
  return (
    <Modal show={props.percentage !== 0} showClose={false} onClose={() => {}}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          Sto decifrando le risposte... Avanzamento: {props.percentage.toFixed(0)}%
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default StatsDecriptionModal;
