import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ErrorModalContext } from '../components/ErrorModal';
import API from '../utils/api';
import moment from 'moment';
import 'bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css';
import { decodeId } from '../utils/encode-id';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faViruses,
  faUserFriends,
  faBookOpen,
  faChartLine,
  faExclamationTriangle,
  faCheckCircle,
  faFolder,
  faLock,
  faBalanceScale,
  faGlobe,
  faCogs,
  faProjectDiagram,
  faSearchLocation,
  faAward,
  faPlusCircle,
  faListOl
} from '@fortawesome/free-solid-svg-icons';
const { Heading } = require('react-bulma-components');

type Props = RouteComponentProps<{
  userId: string;
  surveyId: string;
}>;

const Scores: FunctionComponent<Props> = (props) => {
  const errorModalContext = useContext(ErrorModalContext);
  const [scores, setScores] = useState(undefined as any);

  const icons = {
    'Human factor': faUserFriends,
    'COVID-19': faViruses,
    'Investimenti in cyber security': faChartLine,
    'Awareness su policy, norme e regolamenti': faBalanceScale,
    'Valutazione e percezione della minaccia cyber': faExclamationTriangle,
    Prevenzione: faCheckCircle,
    'Backup e conservazione dei dati': faFolder,
    'Software security': faLock,
    'Inventario di sistemi e dispositivi': faListOl,
    'Web security': faGlobe,
    'Security operations': faCogs,
    'Cyber security e azioni a livello organizzazione': faProjectDiagram,
    'Valutazione del rischio cyber': faSearchLocation,
    'Accessi e autenticazione': faAward,
    Formazione: faBookOpen
  };

  const getIcon = (name: keyof typeof icons) => {
    return icons[name];
  };

  useEffect(() => {
    errorModalContext.displayError('');
    (async () => {
      try {
        const downloadedScores = await API.getUserScores(
          decodeId(props.match.params.userId),
          decodeId(props.match.params.surveyId)
        );
        downloadedScores.sort((a: any, b: any) => {
          return moment(a.timestamp) > moment(b.timestamp) ? -1 : 1;
        });
        setScores(downloadedScores);
      } catch (error) {
        errorModalContext.displayError(error.toString());
      }
    })();
    // eslint-disable-next-line
  }, [props.match.params.userId]);

  const getColor = (num: any) => {
    return num < 33.333 ? 'is-danger' : num > 66.66 ? 'is-success' : 'is-warning';
  };

  return (
    (scores && (
      <>
        <section className="hero has-background-grey-lighter is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12-mobile is-10-desktop is-8-widescreen">
                  <div className="box">
                    <div
                      className="field"
                      style={{ paddingTop: '1.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      <Heading className="has-text-centered">Analizza il tuo progresso</Heading>

                      <div className="timeline">
                        <header className="timeline-header">
                          <span className="tag is-medium is-info">Oggi</span>
                        </header>

                        {scores.map((score: any) => (
                          <>
                            <div
                              className="timeline-item"
                              key={score.timestamp}
                              style={{ paddingTop: '1rem' }}
                            >
                              <div className="timeline-marker is-info is-image is-icon is-32-32">
                                <FontAwesomeIcon icon={faPlusCircle} className="is-pulled-left" />
                              </div>

                              <div className="timeline-content" style={{ width: '100%' }}>
                                <p className="heading">
                                  {moment(score.timestamp).format('DD-MM-yyyy HH:mm')}
                                </p>
                                <Heading size={5} className="has-text-centered">
                                  Punteggio: {(score.score.total * 100).toFixed(2)}%
                                </Heading>
                                <div className="progress-wrapper">
                                  <progress
                                    className={
                                      'progress is-small ' + getColor(score.score.total * 100)
                                    }
                                    value={score.score.total * 100}
                                    max="100"
                                  ></progress>
                                </div>
                              </div>
                            </div>

                            {score.score.categories.map((catScore: any, i: number) => {
                              let sc = catScore.score * 100;
                              let perc = sc.toFixed(2);
                              return (
                                <div
                                  className="timeline-item"
                                  key={catScore.name}
                                  style={{ paddingBottom: '0rem' }}
                                >
                                  <div className="timeline-marker is-light is-image is-icon is-32-32">
                                    {catScore.name in icons ? (
                                      <FontAwesomeIcon icon={getIcon(catScore.name)} />
                                    ) : (
                                      ''
                                    )}
                                  </div>

                                  <div className="timeline-content" style={{ width: '100%' }}>
                                    <div className="progress-wrapper">
                                      <p className="progress-value is-white has-text-left">
                                        {catScore.name}: <b className="is-pulled-right">{perc}%</b>
                                      </p>
                                      <progress
                                        className={'progress is-small ' + getColor(sc)}
                                        value={perc}
                                        max="100"
                                      ></progress>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ))}
                        <div className="timeline-header">
                          <span className="tag is-medium is-primary">Inizio</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )) || <>Loading...</>
  );
};

export default Scores;
