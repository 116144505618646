import React, { FunctionComponent } from 'react';
import 'bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css';
const { Heading } = require('react-bulma-components');

const NotFound: FunctionComponent = () => {
  return (
    <>
      <section className="hero has-background-grey-lighter is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12-mobile is-10-desktop is-8-widescreen">
                <div className="box">
                  <div className="field" style={{ padding: '1.5rem' }}>
                    <Heading className="has-text-centered">Pagina non trovata</Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
