import React, { FunctionComponent, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api';
import { ErrorModalContext } from '../../components/ErrorModal';
import moment from 'moment';
import URLModal from '../../components/admin/URLModal';
import ConfirmModal from '../../components/admin/ConfirmModal';
import { encodeId } from '../../utils/encode-id';
const { Button, Container, Table, Heading } = require('react-bulma-components');

const Surveys: FunctionComponent = () => {
  const errorModalContext = useContext(ErrorModalContext);
  const history = useHistory();
  const [surveys, setSurveys] = useState([] as any[]);
  const [urlModal, setUrlModal] = useState({} as { survey?: string; stats?: string });
  const [deletingSurvey, setDeletingSurvey] = useState(<></>);

  const getSurveys = async () => {
    try {
      const surveys = await API.getSurveys();
      setSurveys(surveys);
    } catch (error) {
      errorModalContext.displayError(error.toString(), false);
    }
  };

  useEffect(() => {
    errorModalContext.displayError('');
    getSurveys();
    // eslint-disable-next-line
  }, []);

  const onEdit = (id?: string) => () => {
    history.push(id ? `/admin/survey/${encodeId(id)}` : '/admin/survey/new');
  };

  const onLogout = () => {
    history.push('/admin/logout');
  };

  const onURL = (id: string) => () => {
    setUrlModal({
      survey: `${window.location.origin}/#/survey/${encodeId(id)}`,
      stats: `${window.location.origin}/#/stats/${encodeId(id)}`
    });
  };

  const onDelete = (survey: any) => () => {
    setDeletingSurvey(
      <ConfirmModal
        show={true}
        content={
          <>
            Sei sicuro di voler eliminare <b>{survey.title}</b>
          </>
        }
        button={'Elimina'}
        onDelete={async () => {
          try {
            await API.deleteSurvey(survey._id);
            await getSurveys();
            setDeletingSurvey(<></>);
          } catch (error) {
            errorModalContext.displayError(error.toString(), false);
          }
        }}
        onCancel={() => setDeletingSurvey(<></>)}
      />
    );
  };

  return (
    <>
      <URLModal
        surveyUrl={urlModal.survey}
        statsUrl={urlModal.stats}
        onClose={() => setUrlModal({})}
      />
      {deletingSurvey}

      <section className="hero has-background-grey-lighter is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12-mobile">
                <div className="box">
                  <div className="field">
                    <Container style={{ padding: '1em', backgroundColor: '#fff' }}>
                      <div className="has-text-right">
                        <Button color="danger" onClick={onLogout}>
                          Logout
                        </Button>
                      </div>

                      <Heading size={3} className="has-text-centered is-clearfix">
                        CyberReadiness.IT
                      </Heading>

                      <Heading size={5} className="has-text-centered">
                        Piattaforma di amministrazione
                      </Heading>

                      <div className="has-text-centered">
                        <Button color="success" onClick={onEdit()}>
                          Aggiungi questionario
                        </Button>
                      </div>

                      <br></br>

                      <Table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="has-text-centered">Titolo</th>
                            <th className="has-text-centered">Risposte</th>
                            <th className="has-text-centered">Inizio</th>
                            <th className="has-text-centered">Fine</th>
                            <th className="has-text-centered">Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                          {surveys.map((survey) => (
                            <tr key={survey._id}>
                              <th className="has-text-centered" style={{ verticalAlign: 'middle' }}>
                                {survey.title}
                              </th>
                              <td className="has-text-centered" style={{ verticalAlign: 'middle' }}>
                                {survey.answers}
                              </td>
                              <td className="has-text-centered" style={{ verticalAlign: 'middle' }}>
                                {survey.start && moment(survey.start).format('DD/MM/yyyy HH:mm')}
                              </td>
                              <td className="has-text-centered" style={{ verticalAlign: 'middle' }}>
                                {survey.end && moment(survey.end).format('DD/MM/yyyy HH:mm')}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Button.Group position="centered">
                                  <Button color="info" onClick={onURL(survey._id)}>
                                    URL
                                  </Button>
                                  <Button color="warning" onClick={onEdit(survey._id)}>
                                    Modifica
                                  </Button>
                                  <Button color="danger" onClick={onDelete(survey)}>
                                    Elimina
                                  </Button>
                                </Button.Group>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Surveys;
