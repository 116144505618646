import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import API from '../utils/api';
import UserIdModal from '../components/UserIdModal';
import { ErrorModalContext } from '../components/ErrorModal';
import QuestionModel from '../components/questions';
import Encryption from '../utils/encryption';
import { calculateScore } from '../utils/score-calculator';
import { decodeId, encodeId } from '../utils/encode-id';
const { Heading, Button } = require('react-bulma-components');

type Props = RouteComponentProps<{
  id: string;
}>;

const Survey: FunctionComponent<Props> = (props) => {
  const errorModalContext = useContext(ErrorModalContext);
  const history = useHistory();
  const [survey, setSurvey] = useState(undefined as any);
  const [userId, setUserId] = useState(undefined as string | undefined);
  const [defaultLocked, setDefaultLocked] = useState([] as number[]);
  const [unlocked, setUnlocked] = useState([] as number[]);

  useEffect(() => {
    errorModalContext.displayError('');
    (async () => {
      try {
        const survey = await API.getSurvey(decodeId(props.match.params.id));
        const defaultLocked = [];
        for (const q of survey.questions)
          for (const a of q.answers)
            if (a.unlocks) for (const u of a.unlocks) defaultLocked.push(u);
        setDefaultLocked(defaultLocked);
        const answers = [];
        // eslint-disable-next-line
        for (const _ in survey.questions) {
          answers.push([]);
        }
        setAnswerState(answers);
        setSurvey(survey);
      } catch (error) {
        errorModalContext.displayError(error.toString());
      }
    })();
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const [answer, setAnswerState] = useState([] as any[]);
  const setAnswer = (i: number) => (a: number[]) => {
    const newAnswers = [...answer];
    newAnswers[i] = a;

    // Check unlocks
    const newUnlocked: number[] = [];
    for (const qi in survey.questions) {
      for (const a of newAnswers[qi as any]) {
        if (a !== -1) {
          const unlocks = survey.questions[qi].answers[a].unlocks;
          if (unlocks) {
            for (const u of unlocks) {
              newUnlocked.push(u);
            }
          }
        }
      }
    }

    // Reset locked answer responses
    const locked = defaultLocked.filter((x) => !newUnlocked.includes(x));
    for (const l of locked) {
      newAnswers[l] = [];
    }

    setUnlocked(newUnlocked);
    setAnswerState(newAnswers);
  };

  let alreadySubmitted = false;

  const submitAnswer = async () => {
    if (alreadySubmitted) return;
    alreadySubmitted = true;

    // Do not use array.reduce, it skips empty cells
    const countUnanswered = (array: any[]) => {
      let unanswered = 0;
      for (let i = 0; i < array.length; i++) {
        if (defaultLocked.includes(i) && !unlocked.includes(i)) continue;
        if (survey.questions[i].type === 'none') continue; // Skip text questions
        if (survey.questions[i].type === 'image') continue; // Skip image questions
        if (answer[i].length === 0) unanswered++;
      }
      return unanswered;
    };

    if (countUnanswered(answer) > 0) {
      errorModalContext.displayError("Devi compilare l'intero questionario", true);
      return;
    }

    try {
      const score = calculateScore(survey, answer);

      const answerString = JSON.stringify(answer);

      const answers: { [publicKey: string]: string } = {};

      for (const key of survey.keys) {
        const publicKey = await Encryption.loadPublicKey(key.publicKey);
        answers[key.publicKey] = await Encryption.encrypt(publicKey, answerString);
      }

      await API.postAnswer(userId!, survey._id, answers, score);
      history.push(`/scores/${encodeId(userId!)}/${encodeId(survey._id)}`);
    } catch (error) {
      errorModalContext.displayError(error.toString());
    }
  };

  return (
    (survey && (
      <>
        <UserIdModal
          show={!userId}
          setUserId={setUserId}
          title={survey.title}
          description={survey.questions[0].text}
        />

        <section className="hero has-background-grey-lighter is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12-mobile is-10-desktop is-8-widescreen">
                  <div className="box" style={{ padding: '2rem', paddingTop: '3rem' }}>
                    <div className="columns is-centered is-vcentered">
                      <div className="column is-12-mobile is-36-fullhd has-text-centered">
                        <a
                          href="https://cybersecnatlab.it/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="cybersecnatlab.png"
                            alt="Logo CyberSecNatLAb"
                            style={{ width: '75%' }}
                          />
                        </a>
                      </div>
                      <div className="column is-12-mobile is-6-fullhd has-text-centered">
                        <a href="#/" target="_blank" rel="noopener noreferrer">
                          <img
                            src="logo.svg"
                            alt="Logo CyberReadiness.IT"
                            style={{ width: '100%' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="field">
                      <Heading className="has-text-centered mt-3">{survey.title}</Heading>

                      {survey.questions.map((question: any, i: number) => {
                        if (i === 0) return <></>;
                        if (defaultLocked.includes(i) && !unlocked.includes(i))
                          return <Fragment key={i}></Fragment>;

                        return (
                          <QuestionModel
                            key={i}
                            question={question}
                            userAnswer={answer[i]}
                            onChange={setAnswer(i)}
                          />
                        );
                      })}

                      <div className="has-text-centered">
                        <Button onClick={submitAnswer} className="is-success">
                          Invia
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )) || <></>
  );
};

export default Survey;
