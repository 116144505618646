import React, { FunctionComponent } from 'react';
import SelectSearch from 'react-select-search';
import './DropDown.css';
const {
  Form: { Field, Label }
} = require('react-bulma-components');

type Props = {
  question: any;
  userAnswer: number[];
  onChange: (answer: number[]) => any;
};

const DropDown: FunctionComponent<Props> = ({ question, userAnswer, onChange }) => {
  const onChangeEvent = (value: any) => {
    const answer = parseInt(value);
    if (userAnswer[0] !== answer) {
      onChange([answer]);
    }
  };

  const options = [];
  for (const i in question.answers) {
    const q = question.answers[i];
    const option = {
      name: q.text,
      value: i
    };
    options.push(option);
  }

  return (
    <Field>
      <Label className="question-title">{question.text}</Label>
      <SelectSearch
        search={true}
        options={options}
        value={userAnswer[0] === -1 ? undefined : userAnswer[0]?.toString()}
        onChange={onChangeEvent}
        placeholder={'Seleziona'}
        renderValue={(valueProps, snapshot, className) => (<div {...(valueProps as any)} className={className}>{valueProps.value}</div>) as any}
      />
    </Field>
  );
};

export default DropDown;
