import React, { FunctionComponent } from 'react';
import './index.css';
import DropDown from './DropDown';
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import Text from './Text';
import ImageComponent from './Image';

type Props = {
  question: any;
  userAnswer: any;
  onChange: (answer: any) => any;
};

const Base: FunctionComponent<Props> = ({ question, userAnswer, onChange }: Props) => {
  let element = <>Invalid Question Type</>;
  switch (question.type) {
    case 'none':
      element = <Text question={question} userAnswer={userAnswer} onChange={onChange} />;
      break;
    case 'single':
      element = <SingleChoice question={question} userAnswer={userAnswer} onChange={onChange} />;
      break;
    case 'multiple':
      element = <MultipleChoice question={question} userAnswer={userAnswer} onChange={onChange} />;
      break;
    case 'dropdown':
      element = <DropDown question={question} userAnswer={userAnswer} onChange={onChange} />;
      break;
    case 'image':
      element = <ImageComponent question={question} userAnswer={userAnswer} onChange={onChange} />;
      break;
  }

  return (
    <div className="question">
      {element}
      <hr />
    </div>
  );
};

export default Base;
