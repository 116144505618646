import ky from 'ky';

class API {
  async request(endpoint: string, payload?: any): Promise<any> {
    try {
      const result = await ky
        .post(`/api${endpoint}`, {
          json: payload
        })
        .json();

      return result;
    } catch (error) {
      if (error instanceof ky.HTTPError) {
        throw new APIError((await error.response.json()).message, error.response);
      } else {
        throw new APIError(error.message);
      }
    }
  }

  async adminLogin(password: string) {
    return await this.request('/adminLogin', {
      password
    });
  }

  async logout() {
    return await this.request('/logout');
  }

  async checkJWT() {
    return await this.request('/checkJWT');
  }

  async getSurveys() {
    return await this.request('/getSurveys');
  }

  async createSurvey(survey: any) {
    return await this.request('/createSurvey', {
      survey
    });
  }

  async updateSurvey(survey: any) {
    return await this.request('/updateSurvey', {
      survey
    });
  }

  async getSurvey(id: string) {
    return await this.request('/getSurvey', {
      id
    });
  }

  async deleteSurvey(id: string) {
    return await this.request('/deleteSurvey', {
      id
    });
  }

  async postAnswer(
    userId: string,
    surveyId: string,
    answers: { [publicKey: string]: string },
    score: {
      total: number;
      categories: {
        name: string;
        score: number;
      }[];
    }
  ) {
    return await this.request('/postAnswer', {
      userId,
      surveyId,
      answers,
      score /* We had to sacrifice something, "privacy" or "server side score calculation", we choose the latter */,
      forWhoeverIsLookingAtTheRequest: 'please do not manipulate the score'
    });
  }

  async getEncryptedPrivateKey(surveyId: string, email: string, hashedPassword: string) {
    return await this.request('/getEncryptedPrivateKey', {
      surveyId,
      email,
      hashedPassword
    });
  }

  async getAnswers(surveyId: string, publicKey: string) {
    return await this.request('/getAnswers', {
      surveyId,
      publicKey
    });
  }

  async generateUserId() {
    return (await this.request('/generateUserId')).id;
  }

  async verifyUserId(id: string) {
    try {
      await this.request('/verifyUserId', {
        id
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async getUserScores(userId: string, surveyId: string) {
    return await this.request('/getUserScores', {
      userId,
      surveyId
    });
  }
}

export default new API();

export class APIError extends Error {
  response?: Response;

  constructor(message: string, response?: Response) {
    super(message);

    this.response = response;
  }

  getResponse() {
    return this.response;
  }
}
