function uint8ArrayToBase64(bytes: Uint8Array) {
  let binary = '';
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToUint8Array(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

export default class Encryption {
  static async generateKeypair(passphrase: string) {
    const { privateKey, publicKey } = await crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: { name: 'SHA-256' }
      },
      true,
      ['decrypt']
    );

    const enc = new TextEncoder();
    const baseKey = await crypto.subtle.importKey('raw', enc.encode(passphrase), 'PBKDF2', false, [
      'deriveBits'
    ]);

    const salt = crypto.getRandomValues(new Uint8Array(16));

    const encryptionKeyBits = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        hash: 'SHA-256',
        salt,
        iterations: 10_000
      },
      baseKey,
      256
    );

    const encryptionKey = await crypto.subtle.importKey(
      'raw',
      encryptionKeyBits,
      'AES-CBC',
      false,
      ['encrypt']
    );

    const SPKIPublicKey = await crypto.subtle.exportKey('spki', publicKey);
    const PKCS8PrivateKey = await crypto.subtle.exportKey('pkcs8', privateKey);

    const encryptedPrivateKey = await crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv: salt
      },
      encryptionKey,
      PKCS8PrivateKey
    );

    const encryptedPrivateKeyWithSalt = new Uint8Array(encryptedPrivateKey.byteLength + 16);
    encryptedPrivateKeyWithSalt.set(salt, 0);
    encryptedPrivateKeyWithSalt.set(new Uint8Array(encryptedPrivateKey), 16);
    const encryptedPrivateKeyWithSaltBase64 = uint8ArrayToBase64(encryptedPrivateKeyWithSalt);

    const SPKIPublicKeyBase64 = uint8ArrayToBase64(new Uint8Array(SPKIPublicKey));

    return {
      encryptedPrivateKey: encryptedPrivateKeyWithSaltBase64,
      publicKey: SPKIPublicKeyBase64
    };
  }

  static async loadPrivateKey(encryptedPrivateKeyWithSaltBase64: string, passphrase: string) {
    const encryptedPrivateKeyWithSalt = base64ToUint8Array(encryptedPrivateKeyWithSaltBase64);
    const salt = encryptedPrivateKeyWithSalt.slice(0, 16);
    const encryptedPrivateKey = encryptedPrivateKeyWithSalt.slice(16);

    const enc = new TextEncoder();
    const baseKey = await crypto.subtle.importKey('raw', enc.encode(passphrase), 'PBKDF2', false, [
      'deriveBits'
    ]);

    const encryptionKeyBits = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        hash: 'SHA-256',
        salt,
        iterations: 10_000
      },
      baseKey,
      256
    );

    const encryptionKey = await crypto.subtle.importKey(
      'raw',
      encryptionKeyBits,
      'AES-CBC',
      false,
      ['decrypt']
    );

    const PKCS8PrivateKey = await crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: salt
      },
      encryptionKey,
      encryptedPrivateKey
    );

    const privateKey = await crypto.subtle.importKey(
      'pkcs8',
      PKCS8PrivateKey,
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-256' }
      },
      false,
      ['decrypt']
    );

    return privateKey;
  }

  static async loadPublicKey(SPKIPublicKeyBase64: string) {
    const SPKIPublicKey = base64ToUint8Array(SPKIPublicKeyBase64);

    const publicKey = await crypto.subtle.importKey(
      'spki',
      SPKIPublicKey,
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-256' }
      },
      false,
      ['encrypt']
    );

    return publicKey;
  }

  static async encrypt(publicKey: CryptoKey, data: string) {
    const enc = new TextEncoder();
    const salt = crypto.getRandomValues(new Uint8Array(16));
    const encryptionKeyBits = crypto.getRandomValues(new Uint8Array(16));

    const encryptionKey = await crypto.subtle.importKey('raw', encryptionKeyBits, 'AES-CBC', false, [
      'encrypt'
    ]);

    const encryptedData = await crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv: salt
      },
      encryptionKey,
      enc.encode(data)
    );

    const encryptedKey = await crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
        iv: salt
      },
      publicKey,
      encryptionKeyBits
    );

    const encryptedDataWithSaltAndKey = new Uint8Array(encryptedData.byteLength + 16 + 256);
    encryptedDataWithSaltAndKey.set(salt, 0);
    encryptedDataWithSaltAndKey.set(new Uint8Array(encryptedKey), 16);
    encryptedDataWithSaltAndKey.set(new Uint8Array(encryptedData), 16 + 256);

    const encryptedDataWithSaltAndKeyBase64 = uint8ArrayToBase64(encryptedDataWithSaltAndKey);

    return encryptedDataWithSaltAndKeyBase64;
  }

  static async decrypt(privateKey: CryptoKey, encryptedDataWithSaltBase64: string) {
    const encryptedDataWithSalt = base64ToUint8Array(encryptedDataWithSaltBase64);
    const salt = encryptedDataWithSalt.slice(0, 16);
    const encryptedKey = encryptedDataWithSalt.slice(16, 16 + 256);
    const encryptedData = encryptedDataWithSalt.slice(16 + 256);

    const encryptionKeyBits = await crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
        iv: salt
      },
      privateKey,
      encryptedKey
    );

    const encryptionKey = await crypto.subtle.importKey(
      'raw',
      encryptionKeyBits,
      'AES-CBC',
      false,
      ['decrypt']
    );

    const data = await crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: salt
      },
      encryptionKey,
      encryptedData
    );

    const dec = new TextDecoder();

    return dec.decode(data);
  }

  static async hash(data: string) {
    const enc = new TextEncoder();
    const baseKey = await crypto.subtle.importKey('raw', enc.encode(data), 'PBKDF2', false, [
      'deriveBits'
    ]);

    const result = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        hash: 'SHA-256',
        salt: enc.encode('_Totally_Random_'),
        iterations: 10_000
      },
      baseKey,
      256
    );

    return uint8ArrayToBase64(new Uint8Array(result));
  }
}
