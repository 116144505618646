const getAnswerScore = (survey: any, answer: any, question: number) => {
  if (answer[question].length === 0) return -1;

  let totalScore = 0;

  for (const a of answer[question]) {
    if (survey.questions[question].answers[a].value === undefined) {
      return -1;
    }

    totalScore += survey.questions[question].answers[a].value;
  }

  return totalScore;
};

export const calculateScore = (survey: any, answer: any) => {
  let score = 0;
  const categoriesScore = [];

  const defaultLocked = [];
  for (const q of survey.questions)
    for (const a of q.answers) if (a.unlocks) for (const u of a.unlocks) defaultLocked.push(u);

  for (const category of survey.categories) {
    let categoryScore = 0;
    let categoryCount = 0;
    for (let i = 0; i < survey.questions.length; i++)
      if (survey.questions[i].category === category.name) {
        const score = getAnswerScore(survey, answer, i);
        if (score !== -1) {
          categoryScore += score;
          if (!defaultLocked.includes(i)) categoryCount++;
        }
      }

    const catScore = (categoryScore / categoryCount) * category.weight;
    if (!isNaN(catScore)) {
      categoriesScore.push({
        name: category.description as string,
        score: categoryScore / categoryCount
      });
      score += catScore;
    }
  }

  return {
    total: score,
    categories: categoriesScore
  };
};
