import React, { FunctionComponent, useContext, useState } from 'react';
import API from '../utils/api';
import { ErrorModalContext } from './ErrorModal';
import { decodeId, encodeId } from '../utils/encode-id';
import './UserIdModal.css';

const {
  Heading,
  Modal,
  Section,
  Content,
  Button,
  Form: { Input }
} = require('react-bulma-components');

type Props = {
  show: boolean;
  description: string;
  title: string;
  setUserId: (userId: string) => any;
};

const UserIdModal: FunctionComponent<Props> = ({ show, description, title, setUserId }) => {
  const errorModalContext = useContext(ErrorModalContext);
  const [tmpUserId, setTmpUserId] = useState(undefined as string | undefined);
  const [idTextInput, setIdTextInput] = useState('');
  const [invalid, setInvalid] = useState(false);

  const onIdTextInputChange = (event: any) => {
    if (invalid) {
      setInvalid(false);
    }

    setIdTextInput(event.target.value);
  };

  const verifyUserId = async () => {
    if (!idTextInput) {
      return setInvalid(true);
    }

    try {
      if (await API.verifyUserId(decodeId(idTextInput))) {
        setUserId(decodeId(idTextInput));
        setInvalid(false);
      } else {
        setInvalid(true);
      }
    } catch (error) {
      setUserId('INVALID');
      errorModalContext.displayError(error.toString());
    }
  };

  const generateUserId = async () => {
    try {
      const userId = await API.generateUserId();
      setTmpUserId(userId);
    } catch (error) {
      setUserId('INVALID');
      errorModalContext.displayError(error.toString());
    }
  };

  const confirmUserId = async () => {
    if (!tmpUserId) {
      errorModalContext.displayError('Empty ID');
    } else {
      setUserId(tmpUserId);
    }
  };

  return (
    <Modal show={show} showClose={false} onClose={() => {}}>
      <Modal.Content>
        <Section style={{ backgroundColor: 'white' }}>
          {!tmpUserId ? (
            <>
              <div className="columns is-centered is-vcentered">
                <div className="column is-12-mobile is-36-fullhd has-text-centered">
                  <a href="https://cybersecnatlab.it/" target="_blank" rel="noopener noreferrer">
                    <img
                      src="cybersecnatlab.png"
                      alt="Logo CyberSecNatLAb"
                      style={{ width: '75%' }}
                    />
                  </a>
                </div>
                <div className="column is-12-mobile is-6-fullhd has-text-centered">
                  <a href="#/" target="_blank" rel="noopener noreferrer">
                    <img src="logo.svg" alt="Logo CyberReadiness.IT" style={{ width: '100%' }} />
                  </a>
                </div>
              </div>

              <Heading className="has-text-centered">{title}</Heading>
              <hr />
              <Content style={{ whiteSpace: 'pre-line' }}>{description}</Content>
              <hr />
              <Heading className="has-text-centered">Nuovo utente?</Heading>
              <div className="has-text-centered">
                Clicca qui per generare un nuovo ID, utilizzalo per tracciare i tuoi progressi
              </div>
              <br />
              <div className="has-text-centered">
                <Button color="link" onClick={generateUserId}>
                  Genera un nuovo ID
                </Button>
              </div>
              <div style={{ height: '3em' }}></div>
              <Heading className="has-text-centered">Possiedi già un ID?</Heading>
              <div className="has-text-centered">Inserisci qui il tuo ID</div>
              <br />
              <Input
                placeholder="ID"
                color={invalid ? 'danger' : null}
                value={idTextInput}
                onChange={onIdTextInputChange}
              />
              {invalid && <div style={{ color: '#f00' }}>ID Invalido</div>}
              <div className="has-text-centered">
                <Button color="link" onClick={verifyUserId} style={{ marginTop: '1em' }}>
                  Conferma
                </Button>
              </div>
            </>
          ) : (
            <>
              <Heading className="has-text-centered">Il tuo ID</Heading>
              Di seguito l'identificativo anonimo generato per la compilazione. <br /> <br />
              <b>
                Ti invitiamo a salvare l'identificativo per consultare in un secondo momento l'esito
                del questionario associato e per ricevere le eventuali azioni di recupero
                consigliate.
              </b>
              <br /> <br />
              <Input readOnly={true} value={encodeId(tmpUserId)} />
              <div className="has-text-centered">
                <Button color="link" onClick={confirmUserId} style={{ marginTop: '1em' }}>
                  Continua
                </Button>
              </div>
            </>
          )}
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export default UserIdModal;
